[data-category-filter-open="true"] {
  button {
    @apply bg-black;
    @apply border-black;
    @apply text-white;
    span {
      @apply rotate-45;
    }
  }

  ul {
    @apply opacity-100;
    @apply pointer-events-auto;
  }

  a {
    @apply pb-2;
    @apply pt-3;
    @apply px-2;
  }
}