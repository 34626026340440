.styled-select {
  @apply border;
  @apply border-gray-300;
  @apply block;
  @apply py-1;
  @apply pl-3;
  @apply relative;
  @apply rounded;

  @apply before:bg-gray-400;
  @apply before:[clip-path:polygon(100%_0%,0_0%,50%_100%)];
  @apply before:content-[''];
  @apply before:h-2;
  @apply before:pointer-events-none;
  @apply before:absolute;
  @apply before:opacity-50;
  @apply before:right-2;
  @apply before:top-1/2;
  @apply before:-translate-y-1/2;
  @apply before:w-3;
  @apply before:z-[1];

  select {
    @apply appearance-none;
    @apply block;
    @apply outline-0;
    @apply pr-4;
    @apply text-base;
    @apply w-full;
  }

  // border: 1px solid $grey-lt;
  // border-radius: 0.25 * $font-size-default;
  // display: block;
  // padding: 0.5 * $font-size-default 0 0.5 * $font-size-default 0.5 *
  //   $font-size-default;
  // position: relative;

  // select {
  //   padding-right: 2 * $font-size-default;
  // }

  // &::before {
  //   background-color: lighten($grey-md, 20%);
  //   clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  //   content: "";
  //   height: 0.75rem;
  //   pointer-events: none;
  //   position: absolute;
  //   right: 0.5 * $font-size-default;
  //   top: 50%;
  //   transform: translateY(-50%);
  //   width: 1rem;
  //   z-index: 1;
  // }
}