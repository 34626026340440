[data-icon-split-block-active="false"] {
  @apply [clip-path:circle(0)];
  @apply opacity-0;
  @apply absolute;
  @apply z-[-1];
}
[data-icon-split-block-active="true"] {
  @apply [clip-path:none];
  @apply opacity-100;
  @apply static;
  @apply z-0;
}