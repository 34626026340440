@import "src/styles/variables.scss";
@import url("https://use.typekit.net/vpt7uzt.css");

@font-face {
  font-family: "Cooper Hewitt";
  src: url("/fonts/CooperHewitt-Bold.woff");
  font-weight: 900;
}

@font-face {
  font-family: "Cooper Hewitt";
  src: url("/fonts/CooperHewitt-BoldItalic.woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Cooper Hewitt";
  src: url("/fonts/CooperHewitt-Medium.woff");
  font-weight: bold;
}

@font-face {
  font-family: "Cooper Hewitt";
  src: url("/fonts/CooperHewitt-MediumItalic.woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Cooper Hewitt";
  src: url("/fonts/CooperHewitt-Book.woff");
  font-weight: normal;
}

@font-face {
  font-family: "Cooper Hewitt";
  src: url("/fonts/CooperHewitt-BookItalic.woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Cooper Hewitt";
  src: url("/fonts/CooperHewitt-Light.woff");
  font-weight: 300;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply overflow-x-hidden;
  @apply pt-[84px];
  @apply sm:pt-[104px];
  @apply lg:pt-[120px];
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-sans;
}

.prose {
  ol,
  ul {
    li,
    li p {
      margin: 0 !important;
    }
  }
}

@import "./_mixins.scss";

@import "./_account.scss";
@import "./_cart-flyout.scss";
@import "./_category-filter.scss";
@import "./_checkout.scss";
@import "./_container.scss";
@import "./_faqs.scss";
@import "./_header.scss";
@import "./_icon-split-block.scss";
@import "./_journal-share-bar.scss";
@import "./_ocn.scss";
@import "./_reviews-slider.scss";
@import "./_slider.scss";
@import "./_styled-select.scss";
@import "./_tabbed-list.scss";

.yotpo-widget-campaign-widget-icon-container svg {
  display: inline-block;
}
