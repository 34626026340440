h3[data-faq-open="true"] {
  @apply mb-2;
}

[data-faq-answer-open="false"] {
  @apply hidden;
}
[data-faq-answer-open="true"] {
  @apply flex;
}
svg[data-faq-open="false"] {
    @apply rotate-0;
}
svg[data-faq-open="true"] {
    @apply rotate-45;
}