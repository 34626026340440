// Header Scrolled Animation
[data-scrolled="true"] {
  @apply shadow;
}

// Header Cart Icon
[data-cart-has-items="true"] {
  @apply text-orange;
  span {
    @apply absolute;
    @apply font-sans;
    @apply font-extrabold;
    @apply right-0;
    @apply text-xs;
    @apply top-1/2;
    @apply text-black;
    @apply translate-x-full;
    @apply -translate-y-2/3;
  }
}

// Header Cart Icon
[data-cart-is-loading="true"] {
  @apply opacity-30;
  @apply pointer-events-none;
}