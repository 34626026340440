.container-desktop {
  @apply clear-both;
  @apply max-w-screen-xl;
  @apply mx-auto;
  @apply px-4;
  @apply lg:px-8;
}

.container-full {
  @apply clear-both;
  @apply w-full;
  @apply mx-auto;
  @apply px-4;
  @apply lg:px-8;
}

.container-laptop {
  @apply clear-both;
  @apply max-w-screen-lg;
  @apply mx-auto;
  @apply px-4;
  @apply lg:px-8;
}

.container-phablet {
  @apply clear-both;
  @apply max-w-screen-sm;
  @apply mx-auto;
  @apply px-4;
  @apply lg:px-8;
}

.container-small,
.container-mobile {
  @apply clear-both;
  @apply max-w-md;
  @apply mx-auto;
  @apply px-4;
  @apply lg:px-8;
}

.container-tablet {
  @apply clear-both;
  @apply max-w-screen-md;
  @apply mx-auto;
  @apply px-4;
  @apply lg:px-8;
}

.container-website {
  @apply clear-both;
  @apply max-w-screen-2xl;
  @apply mx-auto;
  @apply px-4;
  @apply lg:px-8;
}
