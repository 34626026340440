[data-ocn-open="true"] {
  @apply opacity-100;
  @apply translate-x-0;
}

[data-ocn-submenu-open="true"] {
  ol {
    @apply block;
  }
  svg {
    @apply rotate-45;
  }
}