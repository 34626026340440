[data-component="cart-flyout"] {
  @apply hidden;
}
[data-cart-flyout-open] {
  [data-component="cart-flyout"] {
    @apply flex;
    @apply translate-x-full;
  }
}

[data-cart-flyout-open="false"] {
  [data-component="cart-flyout-overlay"] {
    @apply opacity-0;
    @apply pointer-events-none;
  }
  [data-component="cart-flyout"] {
    @apply translate-x-full;
  }
}

[data-cart-flyout-open="true"] {
  @apply overflow-y-clip;
  [data-component="cart-flyout-overlay"] {
    @apply opacity-50;
    @apply cursor-pointer;
  }
  [data-component="cart-flyout"] {
    @apply translate-x-0;
  }
  iframe#launcher {
    @apply hidden;
  }
}
